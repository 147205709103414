import React from "react";
import { useQuery } from "@apollo/client";
import { GET_RECURRING_TRANSACTION_HISTORY } from "../queries/recurringQueries";
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "./ui/Sheet";
import { Transaction } from "../lib/transactionSchema";
import { formatCurrency } from "../lib/utils";
import { ArrowUpIcon, ArrowDownIcon, MinusIcon } from "lucide-react";
import { Spinner } from "./ui/Spinner";

interface Props {
  recurringTransactionId: string;
  isOpen: boolean;
  onClose: () => void;
}

const TrendIcon = ({ trend }: { trend: string }) => {
  switch (trend) {
    case "increasing":
      return <ArrowUpIcon className="text-red-500" />;
    case "decreasing":
      return <ArrowDownIcon className="text-green-500" />;
    default:
      return <MinusIcon className="text-gray-500" />;
  }
};

const RecurringTransactionHistory: React.FC<Props> = ({
  recurringTransactionId,
  isOpen,
  onClose,
}) => {
  const { data, loading, error } = useQuery(GET_RECURRING_TRANSACTION_HISTORY, {
    variables: { recurringTransactionId },
    skip: !isOpen,
  });

  return (
    <Sheet open={isOpen} onOpenChange={onClose}>
      <SheetContent
        className="w-full sm:max-w-lg flex flex-col h-full"
        aria-describedby="transaction-history-description"
      >
        <SheetHeader className="flex-none">
          <SheetTitle>Transaction History</SheetTitle>
          <p id="transaction-history-description" className="sr-only">
            View the history of recurring transactions including amounts and
            dates
          </p>
        </SheetHeader>

        {error && <div className="text-red-500 p-4">{error.message}</div>}

        {loading ? (
          <div className="flex justify-center p-4">
            <Spinner />
          </div>
        ) : (
          <div className="flex-1 overflow-y-auto mt-6">
            <div className="space-y-6">
              <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                <div className="flex items-center gap-2">
                  <TrendIcon trend={data?.recurringTransactionHistory.trend} />
                  <span className="font-medium">
                    {data?.recurringTransactionHistory.trend
                      .charAt(0)
                      .toUpperCase() +
                      data?.recurringTransactionHistory.trend.slice(1)}
                  </span>
                </div>
                <div>
                  <span className="text-sm text-gray-500">Average Amount:</span>
                  <span className="ml-2 font-semibold">
                    {formatCurrency(
                      data?.recurringTransactionHistory.averageAmount
                    )}
                  </span>
                </div>
              </div>

              <div className="space-y-4">
                {data?.recurringTransactionHistory.transactions.map(
                  (transaction: Transaction) => (
                    <div
                      key={transaction.id}
                      className="flex justify-between items-center p-4 border rounded-lg"
                    >
                      <div>
                        <p className="font-medium">
                          {transaction.transactionName}
                        </p>
                        <p className="text-sm text-gray-500">
                          {new Date(
                            transaction.transactionDate
                          ).toLocaleDateString()}
                        </p>
                      </div>
                      <span className="font-semibold">
                        {formatCurrency(transaction.amount)}
                      </span>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        )}
      </SheetContent>
    </Sheet>
  );
};

export default RecurringTransactionHistory;

import React from "react";
import { Button } from "./ui/Button";

const TIME_SEGMENTS = [
  { label: "7 Days", value: "7" },
  { label: "14 Days", value: "14" },
  { label: "30 Days", value: "30" },
  { label: "60 Days", value: "60" },
  { label: "90 Days", value: "90" },
];

interface TimeSegmentSelectorProps {
  selectedSegment: string;
  onSegmentChange: (segment: string) => void;
}

export const TimeSegmentSelector = ({
  selectedSegment,
  onSegmentChange,
}: TimeSegmentSelectorProps) => {
  return (
    <div className="flex gap-2 flex-wrap">
      {TIME_SEGMENTS.map(({ label, value }) => (
        <Button
          key={value}
          variant={selectedSegment === value ? "default" : "outline"}
          onClick={() => onSegmentChange(value)}
          size="sm"
        >
          {label}
        </Button>
      ))}
    </div>
  );
};

import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";
import { TrendingUp } from "lucide-react";
import { Badge } from "./ui/Badge";
import { formatInsightAmount } from "../lib/utils";

const getTypeColor = (type: string) => {
  switch (type.toLowerCase()) {
    case "pattern":
      return "bg-blue-100 text-blue-800 hover:bg-blue-200";
    case "anomaly":
      return "bg-red-100 text-red-800 hover:bg-red-200";
    case "trend":
      return "bg-purple-100 text-purple-800 hover:bg-purple-200";
    default:
      return "bg-gray-100 text-gray-800 hover:bg-gray-200";
  }
};

export const SpendingInsightsPanel = ({ insights }) => {
  if (!insights?.length) return null;

  return (
    <Card className="overflow-hidden">
      <CardHeader className="border-b border-gray-100 bg-gray-50/50">
        <CardTitle className="flex items-center gap-3">
          <TrendingUp className="h-6 w-6 text-blue-500" />
          <div>
            <h3 className="text-xl font-semibold">Spending Patterns</h3>
            <p className="text-base text-gray-500 font-normal">
              Your spending habits and opportunities to save
            </p>
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent className="p-6">
        <div className="space-y-6">
          {insights.map((insight, index) => (
            <div
              key={index}
              className="border-b border-gray-100 pb-6 last:border-0 last:pb-0"
            >
              <div className="flex justify-between items-start mb-3">
                <div>
                  <Badge
                    className={`${getTypeColor(insight.type)} px-3 py-1 text-xs font-medium`}
                  >
                    {insight.type.charAt(0).toUpperCase() +
                      insight.type.slice(1)}
                  </Badge>
                  <div className="text-base text-gray-500 mt-2">
                    {insight.category} • {insight.merchant}
                  </div>
                </div>
                <div className="text-right">
                  <div className="text-base font-medium text-gray-900">
                    Current: {formatInsightAmount(insight.currentSpending)}
                  </div>
                  <div className="text-base text-gray-500">
                    Typical: {formatInsightAmount(insight.typicalSpending)}
                  </div>
                </div>
              </div>
              <p className="text-base text-gray-700 leading-relaxed mb-4">
                {insight.description}
              </p>
              {insight.specificRecommendations?.length > 0 && (
                <div className="bg-gray-50 rounded-lg p-4">
                  <h5 className="text-base font-semibold text-gray-900 mb-3">
                    Recommended Actions
                  </h5>
                  <div className="space-y-4">
                    {insight.specificRecommendations.map((rec, idx) => (
                      <div key={idx} className="text-base">
                        <p className="text-gray-800 font-medium mb-2">
                          {rec.action}
                        </p>
                        <div className="flex justify-between text-sm">
                          <span className="text-green-600 font-medium">
                            Potential Savings:{" "}
                            {formatInsightAmount(rec.savings)}
                          </span>
                          <span className="text-gray-500">
                            Timeline: {rec.timeframe}
                          </span>
                        </div>
                        {rec.implementationSteps?.length > 0 && (
                          <ul className="mt-2 space-y-1">
                            {rec.implementationSteps.map((step, stepIdx) => (
                              <li
                                key={stepIdx}
                                className="text-sm text-gray-600 flex items-start gap-2"
                              >
                                <span className="text-blue-500">•</span>
                                {step}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

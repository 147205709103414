import React, { useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import { format } from "date-fns";
import { GET_UPCOMING_TRANSACTIONS } from "../queries/recurringQueries";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";
import { Separator } from "./ui/Separator";
import { Spinner } from "./ui/Spinner";
import { calculateHowManyDaysTillTargetDate } from "../lib/utils";
import * as LucideIcons from "lucide-react";
import RecurringTransactionHistory from "./RecurringTransactionHistory";

interface Transaction {
  id: string;
  description: string;
  lastAmount: number;
  predictedNextDate: string;
  category?: {
    name: string;
    icon?: string;
  };
  account?: {
    name: string;
    mask?: string;
  };
  matchingTransaction?: any | null;
  transactions?: Array<{
    id: string;
    amount: number;
    transactionDate: string;
    transactionName: string;
  }>;
}

interface TransactionCardProps {
  transaction: Transaction;
}

interface EmptyStateProps {
  message: string;
}

interface TransactionSectionProps {
  title: string;
  transactions: Transaction[];
}

const EmptyState = ({ message }: EmptyStateProps) => (
  <div className="flex flex-col items-center justify-center p-8 text-center">
    <div className="h-12 w-12 rounded-full bg-gray-100 flex items-center justify-center mb-4">
      <i className="fas fa-calendar text-gray-400" />
    </div>
    <p className="text-gray-500">{message}</p>
  </div>
);

const TransactionCard = ({
  transaction,
  onClick,
}: TransactionCardProps & { onClick: (transaction: Transaction) => void }) => {
  const IconComponent = useMemo(() => {
    if (
      transaction.category?.icon &&
      LucideIcons[transaction.category.icon as keyof typeof LucideIcons]
    ) {
      return LucideIcons[transaction.category.icon as keyof typeof LucideIcons];
    }
    return LucideIcons.HelpCircle;
  }, [transaction.category?.icon]);

  const matchingTransaction = transaction.matchingTransaction;
  const isProcessed = matchingTransaction !== null;

  return (
    <div
      onClick={() => onClick(transaction)}
      className="bg-white rounded-lg shadow p-4 flex justify-between items-center hover:bg-gray-50 transition-colors relative cursor-pointer"
    >
      {isProcessed && (
        <div className="absolute -top-2 right-2">
          <div className="bg-green-500 text-white text-xs rounded-full px-2 py-1">
            Already Processed
          </div>
        </div>
      )}
      <div className="flex items-center gap-4">
        <div className="w-10 h-10 bg-gray-50 rounded-full flex items-center justify-center">
          <IconComponent className="h-5 w-5 text-gray-600" />
        </div>
        <div>
          <h3 className="font-semibold">{transaction.description}</h3>
          <div className="text-sm text-gray-600 space-y-1">
            <div>{transaction.category?.name}</div>
            <div>
              {transaction.account?.name}
              {transaction.account?.mask && ` (${transaction.account.mask})`}
            </div>
          </div>
        </div>
      </div>
      <div className="text-right">
        <p className="font-semibold">
          ${(Math.abs(transaction.lastAmount) / 100).toFixed(2)}
        </p>
        <p className="text-sm text-gray-600">
          Expected: {format(new Date(transaction.predictedNextDate), "MMM d")}
        </p>
      </div>
    </div>
  );
};

const TransactionSection = ({
  title,
  transactions,
  onTransactionClick,
}: TransactionSectionProps & {
  onTransactionClick: (transaction: Transaction) => void;
}) => (
  <Card className="w-full">
    <CardHeader>
      <CardTitle>{title}</CardTitle>
    </CardHeader>
    <CardContent>
      <div className="grid gap-4">
        {transactions.length > 0 ? (
          transactions.map((transaction) => (
            <TransactionCard
              key={transaction.id}
              transaction={transaction}
              onClick={onTransactionClick}
            />
          ))
        ) : (
          <EmptyState message="No upcoming transactions for this period" />
        )}
      </div>
    </CardContent>
  </Card>
);

const UpcomingTransactionsHome = () => {
  const [selectedTransaction, setSelectedTransaction] =
    useState<Transaction | null>(null);
  const { data, loading, error } = useQuery(GET_UPCOMING_TRANSACTIONS, {
    variables: { daysAhead: 45 },
  });

  const {
    twoWeekTransactions,
    thirtyDayTransactions,
    fortyFiveDayTransactions,
  } = useMemo(() => {
    if (!data?.upcomingTransactions)
      return {
        twoWeekTransactions: [],
        thirtyDayTransactions: [],
        fortyFiveDayTransactions: [],
      };

    const sorted = [...data.upcomingTransactions].sort((a, b) => {
      if (!a.predictedNextDate || !b.predictedNextDate) {
        return 0;
      }

      return (
        new Date(a.predictedNextDate).getTime() -
        new Date(b.predictedNextDate).getTime()
      );
    });

    return sorted.reduce(
      (acc, transaction) => {
        const daysUntil = calculateHowManyDaysTillTargetDate(
          transaction.predictedNextDate
        );

        if (daysUntil <= 14) {
          acc.twoWeekTransactions.push(transaction);
        } else if (daysUntil <= 30) {
          acc.thirtyDayTransactions.push(transaction);
        } else if (daysUntil <= 45) {
          acc.fortyFiveDayTransactions.push(transaction);
        }

        return acc;
      },
      {
        twoWeekTransactions: [],
        thirtyDayTransactions: [],
        fortyFiveDayTransactions: [],
      }
    );
  }, [data]);

  const handleTransactionClick = (transaction: Transaction) => {
    setSelectedTransaction(transaction);
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500">
        Error loading recurring transactions: {error.message}
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full w-full overflow-auto">
      <div className="flex py-3">
        <div className="flex flex-col w-full">
          <div className="flex flex-col items-center px-4 pb-4">
            <div className="flex flex-row items-center justify-between w-full">
              <h2 className="text-xl font-semibold">Upcoming Transactions</h2>
            </div>
          </div>
          <Separator />
          <div className="p-4 grid gap-6">
            <TransactionSection
              title="Coming up in the next 2 weeks"
              transactions={twoWeekTransactions}
              onTransactionClick={handleTransactionClick}
            />
            <TransactionSection
              title="Later this month"
              transactions={thirtyDayTransactions}
              onTransactionClick={handleTransactionClick}
            />
            <TransactionSection
              title="Next month"
              transactions={fortyFiveDayTransactions}
              onTransactionClick={handleTransactionClick}
            />
          </div>
        </div>
      </div>

      {selectedTransaction && (
        <RecurringTransactionHistory
          recurringTransactionId={selectedTransaction.id}
          isOpen={!!selectedTransaction}
          onClose={() => setSelectedTransaction(null)}
        />
      )}
    </div>
  );
};

export default UpcomingTransactionsHome;

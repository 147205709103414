import { gql } from '@apollo/client';

export const RECOMMENDATION_FIELDS = gql`
  fragment RecommendationFields on Recommendation {
    action
    savings
    timeframe
    implementationSteps
  }
`;

export const SPENDING_INSIGHT_FIELDS = gql`
  fragment SpendingInsightFields on SpendingInsight {
    type
    category
    merchant
    description
    currentSpending
    typicalSpending
    potentialSavings
    confidence
    specificRecommendations {
      action
      savings
      timeframe
      implementationSteps
    }
  }
`;

export const BUDGET_INSIGHT_FIELDS = gql`
  fragment BudgetInsightFields on BudgetInsight {
    sliceName
    status
    amountDiff
    trend
    specificRecommendations {
      action
      impact
      implementationSteps
    }
  }
`;

export const SAVINGS_INSIGHT_FIELDS = gql`
  fragment SavingsInsightFields on SavingsInsight {
    type
    description
    currentAmount
    targetAmount
    monthlyContributionNeeded
    potentialExtraContribution
    timeframe
    specificSteps {
      action
      amount
      frequency
      source
    }
  }
`;

export const RISK_ALERT_FIELDS = gql`
  fragment RiskAlertFields on RiskAlert {
    type
    severity
    description
    amount
    date
    specificActions {
      action
      impact
      deadline
    }
  }
`;

export const OPTIMIZATION_SUGGESTION_FIELDS = gql`
  fragment OptimizationSuggestionFields on OptimizationSuggestion {
    type
    description
    currentCost
    potentialSavings
    paybackPeriod
    specificSteps {
      action
      savings
      effort
      implementationSteps
    }
  }
`;

export const TIME_SEGMENT_FIELDS = gql`
  ${SPENDING_INSIGHT_FIELDS}
  ${BUDGET_INSIGHT_FIELDS}
  ${SAVINGS_INSIGHT_FIELDS}
  ${RISK_ALERT_FIELDS}
  ${OPTIMIZATION_SUGGESTION_FIELDS}
  fragment TimeSegmentFields on TimeSegment {
    spendingInsights {
      ...SpendingInsightFields
    }
    budgetInsights {
      ...BudgetInsightFields
    }
    savingsInsights {
      ...SavingsInsightFields
    }
    riskAlerts {
      ...RiskAlertFields
    }
    optimizationSuggestions {
      ...OptimizationSuggestionFields
    }
  }
`;

import React, { useMemo } from "react";
import { PieChart, Pie, Cell, Tooltip as ChartTooltip } from "recharts";
import { COLORS } from "../../lib/utils";

interface SpendingBreakdownChartProps {
  data: any[];
  colors?: string[];
}

interface ChartConfig {
  [key: string]: {
    label: string;
    color: string;
  };
}

const DEFAULT_COLORS = COLORS;

const SpendingBreakdownChart: React.FC<SpendingBreakdownChartProps> = ({
  data = [],
  colors = DEFAULT_COLORS,
}) => {
  if (!data?.length) {
    return (
      <div className="flex items-center justify-center h-[300px] md:h-[400px] text-gray-500">
        No spending data available
      </div>
    );
  }

  const chartConfig: ChartConfig = useMemo(() => {
    return data.reduce((config, item, index) => {
      if (!item?.category?.id) return config;

      config[item.category.id] = {
        label: item.category.name || "Unknown",
        color: colors[index % colors.length],
      };
      return config;
    }, {} as ChartConfig);
  }, [data, colors]);

  const totalAmount = data.reduce(
    (sum, item) => sum + Math.abs(item?.amount || 0),
    0
  );

  const formattedData = useMemo(() => {
    return data
      .filter((item) => item?.category?.id && item?.amount)
      .map((item, index) => ({
        ...item,
        value: Math.abs(item.amount),
        id: item.category.id,
        color: colors[index % colors.length],
      }));
  }, [data, colors]);

  return (
    <div className="w-full h-[250px] sm:h-[300px] md:h-[400px] relative">
      <PieChart
        width={window.innerWidth < 640 ? 300 : 600}
        height={window.innerWidth < 640 ? 250 : 400}
      >
        <Pie
          data={formattedData}
          cx={window.innerWidth < 640 ? 150 : 300}
          cy="50%"
          labelLine={false}
          outerRadius={window.innerWidth < 640 ? "70%" : "80%"}
          innerRadius={window.innerWidth < 640 ? "35%" : "40%"}
          fill="#8884d8"
          dataKey="value"
        >
          {formattedData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <ChartTooltip
          content={({ payload }) => {
            if (payload?.[0]?.payload) {
              const data = payload[0].payload;
              return (
                <div className="rounded-lg bg-white p-2 shadow-md text-xs">
                  <div className="font-medium">
                    {data.category?.name || "Unknown"}
                  </div>
                  <div className="text-gray-500">
                    ${((data.amount || 0) / 100).toFixed(2)}
                  </div>
                  <div className="text-gray-500">
                    {((data.amount / totalAmount) * 100).toFixed(1)}%
                  </div>
                </div>
              );
            }
            return null;
          }}
        />
      </PieChart>
    </div>
  );
};

export default SpendingBreakdownChart;

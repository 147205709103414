import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";
import { Lightbulb } from "lucide-react";
import { formatInsightAmount } from "../lib/utils";
import { Badge } from "./ui/Badge";

const getEffortColor = (effort: string) => {
  switch (effort.toLowerCase()) {
    case "high":
      return "bg-red-100 text-red-800 hover:bg-red-200";
    case "medium":
      return "bg-yellow-100 text-yellow-800 hover:bg-yellow-200";
    case "low":
      return "bg-green-100 text-green-800 hover:bg-green-200";
    default:
      return "bg-gray-100 text-gray-800 hover:bg-gray-200";
  }
};

export const OptimizationSuggestionsPanel = ({ suggestions }) => {
  if (!suggestions?.length) return null;

  return (
    <Card className="overflow-hidden">
      <CardHeader className="border-b border-gray-100 bg-gray-50/50">
        <CardTitle className="flex items-center gap-3">
          <Lightbulb className="h-6 w-6 text-yellow-500" />
          <div>
            <h3 className="text-xl font-semibold">Smart Optimizations</h3>
            <p className="text-base text-gray-500 font-normal">
              Suggestions to reduce costs and maximize savings
            </p>
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent className="p-6">
        <div className="space-y-6">
          {suggestions.map((suggestion, index) => (
            <div
              key={index}
              className="border-b border-gray-100 pb-6 last:border-0 last:pb-0"
            >
              <div className="flex justify-between items-start mb-3">
                <h4 className="font-medium text-gray-900">
                  {suggestion.type
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                </h4>
                <div className="text-right">
                  <div className="text-base font-medium text-gray-900">
                    Current Cost: {formatInsightAmount(suggestion.currentCost)}
                  </div>
                  <div className="text-base font-medium text-green-600">
                    Potential Savings:{" "}
                    {formatInsightAmount(suggestion.potentialSavings)}
                  </div>
                </div>
              </div>
              <p className="text-base text-gray-700 leading-relaxed mb-2">
                {suggestion.description}
              </p>
              <p className="text-base text-gray-500 mb-4">
                Payback Period: {suggestion.paybackPeriod}
              </p>
              {suggestion.specificSteps?.length > 0 && (
                <div className="bg-gray-50 rounded-lg p-4">
                  <h5 className="text-base font-semibold text-gray-900 mb-3">
                    Implementation Steps
                  </h5>
                  <div className="space-y-4">
                    {suggestion.specificSteps.map((step, idx) => (
                      <div key={idx} className="text-base">
                        <div className="flex justify-between items-center mb-2">
                          <p className="text-gray-800 font-medium">
                            {step.action}
                          </p>
                          <Badge
                            className={`${getEffortColor(step.effort)} px-2 py-0.5`}
                          >
                            {step.effort.charAt(0).toUpperCase() +
                              step.effort.slice(1)}{" "}
                            Effort
                          </Badge>
                        </div>
                        <div className="text-sm font-medium text-green-600 mb-2">
                          Potential Savings: {formatInsightAmount(step.savings)}
                        </div>
                        {step.implementationSteps?.length > 0 && (
                          <ul className="space-y-1">
                            {step.implementationSteps.map((impl, implIdx) => (
                              <li
                                key={implIdx}
                                className="text-sm text-gray-600 flex items-start gap-2"
                              >
                                <span className="text-blue-500">•</span>
                                {impl}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

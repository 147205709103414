import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";
import { PiggyBank } from "lucide-react";
import { formatInsightAmount } from "../lib/utils";
import { Badge } from "./ui/Badge";

const getTypeColor = (type: string) => {
  switch (type.toLowerCase()) {
    case "opportunity":
      return "bg-green-100 text-green-800 hover:bg-green-200";
    case "goal_progress":
      return "bg-blue-100 text-blue-800 hover:bg-blue-200";
    case "optimization":
      return "bg-purple-100 text-purple-800 hover:bg-purple-200";
    default:
      return "bg-gray-100 text-gray-800 hover:bg-gray-200";
  }
};

export const SavingsInsightsPanel = ({ insights }) => {
  if (!insights?.length) return null;

  return (
    <Card className="overflow-hidden">
      <CardHeader className="border-b border-gray-100 bg-gray-50/50">
        <CardTitle className="flex items-center gap-3">
          <PiggyBank className="h-6 w-6 text-green-500" />
          <div>
            <h3 className="text-xl font-semibold">Savings Goals</h3>
            <p className="text-base text-gray-500 font-normal">
              Track progress and find ways to reach your savings targets faster
            </p>
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent className="p-6">
        <div className="space-y-6">
          {insights.map((insight, index) => (
            <div
              key={index}
              className="border-b border-gray-100 pb-6 last:border-0 last:pb-0"
            >
              <div className="flex justify-between items-start mb-3">
                <div>
                  <Badge
                    className={`${getTypeColor(insight.type)} px-3 py-1 text-xs font-medium`}
                  >
                    {insight.type
                      .split("_")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  </Badge>
                </div>
                <div className="text-right">
                  <div className="text-base font-medium text-gray-900">
                    Stashed: {formatInsightAmount(insight.currentAmount)}
                  </div>
                  <div className="text-base font-medium text-green-600">
                    Target: {formatInsightAmount(insight.targetAmount)}
                  </div>
                </div>
              </div>
              <p className="text-base text-gray-700 leading-relaxed mb-4">
                {insight.description}
              </p>
              <div className="bg-gray-50 rounded-lg p-4 mb-3">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <div className="text-sm text-gray-500 mb-1">
                      Monthly Contribution Needed
                    </div>
                    <div className="text-base font-medium text-gray-900">
                      {formatInsightAmount(insight.monthlyContributionNeeded)}
                    </div>
                  </div>
                  <div>
                    <div className="text-sm text-gray-500 mb-1">
                      Potential Extra Contribution
                    </div>
                    <div className="text-base font-medium text-green-600">
                      {formatInsightAmount(insight.potentialExtraContribution)}
                    </div>
                  </div>
                </div>
                <div className="text-sm text-gray-500 mt-2">
                  Goal Timeline: {insight.timeframe}
                </div>
              </div>
              {insight.specificSteps?.length > 0 && (
                <div className="bg-gray-50 rounded-lg p-4">
                  <h5 className="text-base font-semibold text-gray-900 mb-3">
                    Recommended Actions
                  </h5>
                  <div className="space-y-4">
                    {insight.specificSteps.map((step, idx) => (
                      <div key={idx} className="text-base">
                        <p className="text-gray-800 font-medium mb-2">
                          {step.action}
                        </p>
                        <div className="flex justify-between text-sm">
                          <span className="text-green-600 font-medium">
                            Amount: {formatInsightAmount(step.amount)}
                          </span>
                          <span className="text-gray-500">
                            {step.frequency} from {step.source}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";
import { AlertTriangle } from "lucide-react";
import { Badge } from "./ui/Badge";
import { formatInsightAmount } from "../lib/utils";

const getSeverityColor = (severity: string) => {
  switch (severity.toLowerCase()) {
    case "high":
      return "bg-red-100 text-red-800 hover:bg-red-200";
    case "medium":
      return "bg-yellow-100 text-yellow-800 hover:bg-yellow-200";
    case "low":
      return "bg-blue-100 text-blue-800 hover:bg-blue-200";
    default:
      return "bg-gray-100 text-gray-800 hover:bg-gray-200";
  }
};

export const RiskAlertsPanel = ({ alerts }) => {
  if (!alerts?.length) return null;

  return (
    <Card className="overflow-hidden">
      <CardHeader className="border-b border-gray-100 bg-gray-50/50">
        <CardTitle className="flex items-center gap-3">
          <AlertTriangle className="h-6 w-6 text-red-500" />
          <div>
            <h3 className="text-xl font-semibold">Risk Alerts</h3>
            <p className="text-base text-gray-500 font-normal">
              Important actions needed to protect your finances
            </p>
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent className="p-6">
        <div className="space-y-6">
          {alerts.map((alert, index) => (
            <div
              key={index}
              className="border-b border-gray-100 pb-6 last:border-0 last:pb-0"
            >
              <div className="flex justify-between items-start mb-3">
                <div>
                  <h4 className="font-medium text-gray-900">
                    {alert.type
                      .split("_")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  </h4>
                  <div className="text-base text-gray-500 mt-1">
                    Take action: {alert.date}
                  </div>
                </div>
                <Badge
                  className={`${getSeverityColor(alert.severity)} px-3 py-1 text-xs font-medium`}
                >
                  {alert.severity.charAt(0).toUpperCase() +
                    alert.severity.slice(1)}{" "}
                  Risk
                </Badge>
              </div>
              <p className="text-base text-gray-700 leading-relaxed mb-4">
                {alert.description}
              </p>
              <div className="bg-red-50 rounded-lg p-4 mb-3">
                <div className="flex justify-between items-center">
                  <div className="text-sm text-gray-500">Impact Amount</div>
                  <div className="text-base font-medium text-red-600">
                    {formatInsightAmount(alert.amount)}
                  </div>
                </div>
              </div>
              {alert.specificActions?.length > 0 && (
                <div className="bg-gray-50 rounded-lg p-4">
                  <h5 className="text-base font-semibold text-gray-900 mb-3">
                    Recommended Actions
                  </h5>
                  <div className="space-y-4">
                    {alert.specificActions.map((action, idx) => (
                      <div key={idx} className="text-base">
                        <p className="text-gray-800 font-medium mb-2">
                          {action.action}
                        </p>
                        <div className="flex justify-between text-sm">
                          <span className="text-red-600 font-medium">
                            Impact: {formatInsightAmount(action.impact)}
                          </span>
                          <span className="text-gray-500">
                            Recommended Deadline: {action.deadline}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

import { gql } from "@apollo/client";
import { TRANSACTION_FIELDS } from "./transactionQueries";

const GET_UPCOMING_TRANSACTIONS = gql`
  query GetUpcomingTransactions($daysAhead: Int!) {
    upcomingTransactions(daysAhead: $daysAhead) {
      id
      description
      lastAmount
      predictedNextDate
      category {
        id
        name
        icon
      }
      account {
        id
        name
        mask
      }
      matchingTransaction {
        id
        transactionName
        amount
        transactionDate
      }
    }
  }
`;

const GET_RECURRING_TRANSACTION_HISTORY = gql`
  ${TRANSACTION_FIELDS}
  query GetRecurringTransactionHistory($recurringTransactionId: String!) {
    recurringTransactionHistory(recurringTransactionId: $recurringTransactionId) {
      transactions {
        ...TransactionFields
      }
      trend
      averageAmount
    }
  }
`;



export { GET_UPCOMING_TRANSACTIONS, GET_RECURRING_TRANSACTION_HISTORY };

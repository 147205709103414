import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Separator } from "./ui/Separator";
import { Spinner } from "./ui/Spinner";
import { GET_SPENDING_AND_INSIGHTS } from "../queries/overviewQueries";
import DateRangeSelector from "./ui/DateRangeSelector";
import { GENERATE_INSIGHTS } from "../mutations/insightMutations";
import { toast } from "react-hot-toast";
import { Button } from "./ui/Button";
import InsightsDisplay from "./InsightsDisplay";
import SpendingOverview from "./SpendingOverview";

const SpendingHome = () => {
  const [selectedPreset, setSelectedPreset] = useState("30");
  const [dateRange, setDateRange] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
    endDate: new Date(),
  });

  const {
    data: spendingData,
    loading: spendingLoading,
    error: spendingError,
  } = useQuery(GET_SPENDING_AND_INSIGHTS, {
    variables: {
      startDate: dateRange?.startDate?.toISOString(),
      endDate: dateRange?.endDate?.toISOString(),
    },
    skip: !dateRange?.startDate || !dateRange?.endDate,
    fetchPolicy: "network-only",
  });

  const [generateInsights, { loading: generateInsightsLoading }] = useMutation(
    GENERATE_INSIGHTS,
    {
      variables: { input: {} },
      onCompleted: () => {
        toast.success("Generating new insights...");
      },
      onError: (error) => {
        toast.error(`Error generating insights: ${error.message}`);
      },
    }
  );

  const handleDateRangeChange = (newDateRange, preset) => {
    setDateRange(newDateRange);
    setSelectedPreset(preset);
  };

  if (spendingLoading) return <Spinner />;
  if (spendingError)
    return <div>Error loading spending data: {spendingError.message}</div>;

  return (
    <div className="flex flex-col h-full w-full overflow-auto space-y-8 py-3">
      <div className="flex flex-col">
        <div className="flex justify-between items-center px-4 pb-4">
          <h2 className="text-xl font-semibold">Spending</h2>
          <div className="flex items-center gap-4">
            <DateRangeSelector
              initialDateRange={dateRange}
              onDateRangeChange={handleDateRangeChange}
              selectedPreset={selectedPreset}
              setSelectedPreset={setSelectedPreset}
            />
            <Button
              onClick={() => generateInsights()}
              disabled={generateInsightsLoading}
              variant="outline"
            >
              {generateInsightsLoading ? (
                <>
                  <Spinner className="mr-2 h-4 w-4" />
                  Generating...
                </>
              ) : (
                "Generate Insights"
              )}
            </Button>
          </div>
        </div>
        <Separator />
      </div>

      <div className="px-4 space-y-8">
        <SpendingOverview data={spendingData?.spendingBreakdown} />
        <InsightsDisplay />
      </div>
    </div>
  );
};

export default SpendingHome;

import React, { useCallback, useEffect, useState } from "react";
import SafeToSpend from "./SafeToSpend";
import { GET_MOST_RECENT_TRANSACTIONS } from "../queries/transactionQueries";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import RecentTransactionsTable from "./RecentTransactionsTable";
import {
  CHECK_PLAID_SYNC_STATUS,
  GET_ACCOUNTS_FOR_USER,
} from "../queries/plaidQueries";
import {
  COLORS,
  formatCurrency,
  getAccountOptions,
  serializeTransactions,
  getAmountColor,
} from "../lib/utils";
import { Spinner } from "./ui/Spinner";
import PropTypes from "prop-types";
import { GET_CATEGORIES } from "../queries/categoryQueries";
import { TRIGGER_PLAID_SYNC } from "../mutations/plaidMutations";
import toast from "react-hot-toast";
import TransactionDetailsView from "./TransactionDetailsView";
import { TransactionForm } from "./EditTransactionForm";
import { GET_SLICES_FOR_USER } from "../queries/sliceQueries";
import {
  BULK_ASSIGN_TRANSACTIONS_TO_SLICE,
  BULK_CHANGE_TRANSACTION_CATEGORY,
  BULK_CHANGE_TRANSACTION_DESCRIPTION,
  BULK_DELETE_TRANSACTIONS,
  BULK_UNASSIGN_TRANSACTIONS_FROM_SLICE,
} from "../mutations/transactionMutations";
import {
  GET_INCOME_VS_EXPENSES,
  GET_SPENDING_BREAKDOWN,
} from "../queries/overviewQueries";
import DateRangeSelector from "./ui/DateRangeSelector";
import SpendingBreakdownChart from "./ui/SpendingBreakdownChart";
import IncomeVsExpensesChart from "./ui/IncomeVsExpensesChart";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";
import { Separator } from "./ui/Separator";
import { motion } from "framer-motion";
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "./ui/Table";

const Overview = ({
  onDateRangeChange,
  plaidStatusData,
  refetchPlaidStatus,
  dateRange,
  shouldRefereshData,
}) => {
  const [selectedPreset, setSelectedPreset] = useState("14");
  const [recentTransactions, setRecentTransactions] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [syncJobId, setSyncJobId] = useState(null);
  const [syncStatus, setSyncStatus] = useState("idle");
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [slices, setSlices] = useState([]);
  const [spendingBreakdown, setSpendingBreakdown] = useState([]);
  const [incomeVsExpenses, setIncomeVsExpenses] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [recentTransactionsLoading, setRecentTransactionsLoading] =
    useState(false);

  const [filters, setFilters] = useState({
    accountIds: [],
    sliceIds: [],
    categoryIds: [],
    searchTerm: "",
  });

  const handleFilterChange = (filterType, values) => {
    setFilters((prev) => ({
      ...prev,
      [filterType]: values,
    }));
  };

  const [fetchAccounts, { error: accountsError }] = useLazyQuery(
    GET_ACCOUNTS_FOR_USER,
    {
      onCompleted: (data) => {
        setAccounts(data.accountsForUser);
      },
    }
  );

  const [
    getMostRecentTransactions,
    { error: mostRecentTransactionsForUserError },
  ] = useLazyQuery(GET_MOST_RECENT_TRANSACTIONS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.mostRecentTransactionsForUser?.transactions) {
        setRecentTransactions(data.mostRecentTransactionsForUser.transactions);
        setTotalPages(data.mostRecentTransactionsForUser.pagination.totalPages);
        setTotalCount(data.mostRecentTransactionsForUser.pagination.totalCount);
      }
    },
  });

  const [fetchCategories, { error: categoriesError }] = useLazyQuery(
    GET_CATEGORIES,
    {
      onCompleted: (data) => {
        setCategories(data.categories);
      },
    }
  );

  const { data: syncStatusData, refetch: refetchSyncStatus } = useQuery(
    CHECK_PLAID_SYNC_STATUS,
    {
      variables: { jobId: syncJobId },
      skip: !syncJobId,
      pollInterval: 5000,
    }
  );

  const { error: slicesError } = useQuery(GET_SLICES_FOR_USER, {
    onCompleted: (data) => {
      setSlices(data.slicesForUser);
    },
    variables: {
      includeUnassigned: true,
    },
  });

  const [fetchSpendingBreakdown, { error: spendingError }] = useLazyQuery(
    GET_SPENDING_BREAKDOWN,
    {
      onCompleted: (data) => {
        setSpendingBreakdown(data.spendingBreakdown);
      },
    }
  );

  const [fetchIncomeVsExpenses, { error: incomeVsExpensesError }] =
    useLazyQuery(GET_INCOME_VS_EXPENSES, {
      onCompleted: (data) => {
        setIncomeVsExpenses(data.incomeVsExpenses);
      },
    });

  const handleDateRangeChange = useCallback(
    (newDateRange, newPreset) => {
      if (
        newDateRange.startDate instanceof Date &&
        newDateRange.endDate instanceof Date &&
        (newDateRange.startDate.getTime() !== dateRange.startDate.getTime() ||
          newDateRange.endDate.getTime() !== dateRange.endDate.getTime())
      ) {
        // setDateRange(newDateRange);
        setSelectedPreset(newPreset);
        onDateRangeChange(newDateRange);
      }
    },
    [dateRange, onDateRangeChange]
  );

  useEffect(() => {
    if (recentTransactions.length > 0 && accounts.length > 0) {
      const transactions = serializeTransactions(recentTransactions, accounts);
      if (JSON.stringify(transactions) !== JSON.stringify(recentTransactions)) {
        setRecentTransactions(transactions);
      }
    }
  }, [accounts, recentTransactions]);

  useEffect(() => {
    const fetchData = () => {
      if (
        dateRange.startDate instanceof Date &&
        dateRange.endDate instanceof Date &&
        accounts.length > 0
      ) {
        const variables = {
          startDate: dateRange.startDate.toISOString(),
          endDate: dateRange.endDate.toISOString(),
        };

        const transactionsVariables = {
          ...variables,
          page,
          items: perPage,
        };

        handleFetchTransactions({ variables: transactionsVariables });
        fetchSpendingBreakdown({ variables });
        fetchIncomeVsExpenses({ variables });
      }
    };

    fetchData();
  }, [
    dateRange,
    accounts,
    fetchSpendingBreakdown,
    fetchIncomeVsExpenses,
    page,
    perPage,
  ]);

  useEffect(() => {
    fetchAccounts();
    fetchCategories();
    refetchPlaidStatus();
  }, [fetchAccounts, fetchCategories, refetchPlaidStatus]);

  useEffect(() => {
    if (shouldRefereshData) {
      fetchAccounts();
      fetchCategories();
      refetchPlaidStatus();
      handleFetchTransactions();
      fetchIncomeVsExpenses({
        variables: {
          startDate: dateRange.startDate.toISOString(),
          endDate: dateRange.endDate.toISOString(),
        },
      });
      fetchSpendingBreakdown({
        variables: {
          startDate: dateRange.startDate.toISOString(),
          endDate: dateRange.endDate.toISOString(),
        },
      });
    }
  }, [shouldRefereshData]);

  const [bulkDeleteTransactions] = useMutation(BULK_DELETE_TRANSACTIONS);
  const [bulkAssignTransactionsToSlice] = useMutation(
    BULK_ASSIGN_TRANSACTIONS_TO_SLICE
  );
  const [bulkUnassignTransactionsFromSlice] = useMutation(
    BULK_UNASSIGN_TRANSACTIONS_FROM_SLICE
  );
  const [bulkChangeTransactionCategory] = useMutation(
    BULK_CHANGE_TRANSACTION_CATEGORY
  );
  const [bulkChangeTransactionDescription] = useMutation(
    BULK_CHANGE_TRANSACTION_DESCRIPTION
  );

  const lastSyncedAt = plaidStatusData?.user?.plaidLastSyncedAt
    ? new Date(plaidStatusData.user.plaidLastSyncedAt).toLocaleString()
    : "Never";

  const [triggerPlaidSync, { loading: loadingTriggerPlaidSync }] =
    useMutation(TRIGGER_PLAID_SYNC);

  const [isSyncing, setIsSyncing] = useState(false);

  const handleSyncTransactions = async (force = false) => {
    setIsSyncing(true);
    try {
      const { data } = await triggerPlaidSync({ variables: { force } });
      if (data.triggerPlaidSync.jobId) {
        setSyncJobId(data.triggerPlaidSync.jobId);
        setSyncStatus("in_progress");
        toast.success("Sync started. This may take a few minutes.");
        refetchSyncStatus(); // Add this line to refetch the sync status
      } else if (data.triggerPlaidSync.error) {
        toast.info(data.triggerPlaidSync.error);
      }
    } catch (error) {
      toast.error("Failed to start sync: " + error.message);
    } finally {
      refetchPlaidStatus();
    }
  };

  useEffect(() => {
    if (syncStatusData) {
      setSyncStatus(syncStatusData.plaidSyncStatus);
      if (syncStatusData.plaidSyncStatus === "completed") {
        setSyncJobId(null);
        setIsSyncing(false);
        fetchAccounts();
        fetchCategories();
        refetchPlaidStatus();
        handleFetchTransactions();
        toast.success("Sync completed successfully.");
      } else if (syncStatusData.plaidSyncStatus === "error") {
        toast.error("An error occurred during sync. Please try again.");
        setSyncJobId(null);
        setIsSyncing(false);
      }
    }
  }, [syncStatusData]);

  const handleFetchTransactions = useCallback(
    async (options = {}) => {
      setRecentTransactionsLoading(true);
      try {
        const { data } = await getMostRecentTransactions({
          variables: {
            startDate: dateRange.startDate.toISOString(),
            endDate: dateRange.endDate.toISOString(),
            page: options.page || page,
            items: options.perPage || perPage,
            accountIds: options.variables?.accountIds || [],
            categoryIds: options.variables?.categoryIds || [],
            searchTerm: options.variables?.searchTerm || "",
            sliceIds: options.variables?.sliceIds || [],
          },
        });

        if (data?.mostRecentTransactionsForUser) {
          const transactions = serializeTransactions(
            data.mostRecentTransactionsForUser.transactions,
            accounts
          );
          setRecentTransactions(transactions);
          setTotalPages(
            data.mostRecentTransactionsForUser.pagination.totalPages
          );
          setTotalCount(
            data.mostRecentTransactionsForUser.pagination.totalCount
          );
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
        toast.error("Failed to fetch transactions");
      } finally {
        setRecentTransactionsLoading(false);
      }
    },
    [getMostRecentTransactions, page, perPage, accounts, dateRange]
  );

  if (syncStatus === "in_progress") {
    return (
      <div className="absolute inset-0 bg-white/80 flex flex-col items-center justify-center z-50">
        <Spinner />
        <p className="mt-4 text-center text-gray-600 mx-auto">
          Syncing accounts and transactions...
        </p>
      </div>
    );
  }

  if (
    mostRecentTransactionsForUserError ||
    accountsError ||
    categoriesError ||
    slicesError ||
    spendingError ||
    incomeVsExpensesError
  ) {
    return <div>Error loading data</div>;
  }

  const handleBulkDelete = async (transactions) => {
    try {
      const result = await bulkDeleteTransactions({
        variables: { ids: transactions.map((t) => t.id) },
      });
      if (result.data.bulkDeleteTransactions.success) {
        toast.success(`Deleted ${transactions.length} transactions`);
        handleFetchTransactions();
      } else {
        toast.error(result.data.bulkDeleteTransactions.message);
      }
    } catch (error) {
      toast.error("Failed to delete transactions");
    }
  };

  const handleBulkAssign = async (transactions, sliceId) => {
    try {
      const result = await bulkAssignTransactionsToSlice({
        variables: { ids: transactions.map((t) => t.id), sliceId },
      });
      if (result.data.bulkAssignTransactionsToSlice.success) {
        toast.success(`Assigned ${transactions.length} transactions to slice`);
        handleFetchTransactions();
      } else {
        toast.error(result.data.bulkAssignTransactionsToSlice.message);
      }
    } catch (error) {
      toast.error("Failed to assign transactions to slice");
    }
  };

  const handleBulkUnassign = async (transactions) => {
    try {
      const result = await bulkUnassignTransactionsFromSlice({
        variables: { ids: transactions.map((t) => t.id) },
      });
      if (result.data.bulkUnassignTransactionsFromSlice.success) {
        toast.success(
          `Unassigned ${transactions.length} transactions from slice`
        );
        handleFetchTransactions();
      } else {
        toast.error(result.data.bulkUnassignTransactionsFromSlice.message);
      }
    } catch (error) {
      toast.error("Failed to unassign transactions from slice");
    }
  };

  const handleBulkChangeCategory = async (transactions, categoryId) => {
    try {
      const result = await bulkChangeTransactionCategory({
        variables: { ids: transactions.map((t) => t.id), categoryId },
      });
      if (result.data.bulkChangeTransactionCategory.success) {
        toast.success(
          `Changed category for ${transactions.length} transactions`
        );
        handleFetchTransactions();
      } else {
        toast.error(result.data.bulkChangeTransactionCategory.message);
      }
    } catch (error) {
      toast.error("Failed to change transaction categories");
    }
  };

  const handleBulkChangeDescription = async (transactions, description) => {
    try {
      const result = await bulkChangeTransactionDescription({
        variables: { ids: transactions.map((t) => t.id), description },
      });
      if (result.data.bulkChangeTransactionDescription.success) {
        toast.success(
          `Changed description for ${transactions.length} transactions`
        );
        handleFetchTransactions();
      } else {
        toast.error(result.data.bulkChangeTransactionDescription.message);
      }
    } catch (error) {
      toast.error("Failed to change transaction descriptions");
    }
  };

  const daysInRange = Math.ceil(
    (dateRange.endDate.getTime() - dateRange.startDate.getTime()) /
      (1000 * 3600 * 24)
  );

  const handleRowClick = (transaction) => {
    setSelectedTransaction(transaction);
  };

  const handleEditTransaction = (transaction) => {
    setSelectedTransaction(transaction);
    setIsEditing(true);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePerPageChange = (newPageSize) => {
    setPerPage(newPageSize);
    setPage(1);
  };

  return (
    <div className="flex-1 flex-col h-full w-full overflow-x-hidden">
      <div className="flex w-full py-3">
        <div className="flex flex-col space-y-4 w-full px-4 pb-4">
          <SafeToSpend
            accounts={accounts}
            lastSyncedAt={lastSyncedAt}
            onSync={() => handleSyncTransactions(true)}
            syncLoading={
              isSyncing ||
              loadingTriggerPlaidSync ||
              syncStatus === "in_progress"
            }
            slices={slices}
            onMoveFundsSuccess={() => {
              handleFetchTransactions({
                variables: {
                  startDate: dateRange.startDate.toISOString(),
                  endDate: dateRange.endDate.toISOString(),
                  page,
                  items: perPage,
                  accountIds: filters.accountIds || [],
                  categoryIds: filters.categoryIds || [],
                  searchTerm: filters.searchTerm || "",
                  sliceIds: filters.sliceIds || [],
                },
              });
              fetchIncomeVsExpenses({
                variables: {
                  startDate: dateRange.startDate.toISOString(),
                  endDate: dateRange.endDate.toISOString(),
                },
              });
              fetchSpendingBreakdown({
                variables: {
                  startDate: dateRange.startDate.toISOString(),
                  endDate: dateRange.endDate.toISOString(),
                },
              });
            }}
          />
          <DateRangeSelector
            onDateRangeChange={handleDateRangeChange}
            initialDateRange={dateRange}
            selectedPreset={selectedPreset}
            setSelectedPreset={setSelectedPreset}
          />
          <div className="grid grid-cols-1 md:grid-cols-1 mt-4">
            <Card>
              <CardHeader>
                <CardTitle>Spending Breakdown</CardTitle>
              </CardHeader>
              <Separator orientation="horizontal" />
              <CardContent className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="flex items-center justify-center h-full w-full order-1 md:order-1">
                  <div className="w-full max-w-[500px]">
                    {spendingBreakdown.categoryBreakdown &&
                    spendingBreakdown.categoryBreakdown.length > 0 ? (
                      <SpendingBreakdownChart
                        data={spendingBreakdown.categoryBreakdown}
                        colors={COLORS}
                      />
                    ) : (
                      <div>No spending breakdown data</div>
                    )}
                  </div>
                </div>
                <div className="border-none md:border-l pl-0 md:pl-6 relative z-10 order-2 md:order-2">
                  <div className="space-y-4 sm:space-y-2 w-full">
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      {spendingBreakdown.categoryBreakdown?.map(
                        (category, index) => {
                          const totalSpending =
                            spendingBreakdown.categoryBreakdown.reduce(
                              (sum, item) => sum + Math.abs(item.amount),
                              0
                            );
                          const percentage =
                            category?.amount && totalSpending
                              ? (
                                  (Math.abs(category.amount) / totalSpending) *
                                  100
                                ).toFixed(1)
                              : "0.0";

                          return (
                            <div
                              key={category.category.id}
                              className="flex items-center justify-between hover:bg-gray-50 p-2 rounded-lg transition-colors w-full"
                            >
                              <div className="flex items-center gap-3">
                                <div
                                  className="w-4 h-4 rounded-full flex-shrink-0"
                                  style={{
                                    backgroundColor:
                                      COLORS[index % COLORS.length],
                                  }}
                                />
                                <span className="font-medium leading-none">
                                  {category.category.name}
                                </span>
                              </div>
                              <div className="text-right">
                                <div
                                  className={`${getAmountColor(category.amount, "text")} font-semibold`}
                                >
                                  {formatCurrency(category.amount)}
                                </div>
                                <div className="text-sm text-gray-500 mt-0.5">
                                  {percentage}%
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </motion.div>
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card className="mt-4">
              <CardHeader>
                <CardTitle>Earned vs Spent</CardTitle>
              </CardHeader>
              <Separator orientation="horizontal" />
              <CardContent>
                {incomeVsExpenses ? (
                  <div className="flex flex-col gap-4 items-center">
                    <div className="w-full mt-4">
                      <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Table>
                          <TableHeader>
                            <TableRow>
                              <TableHead className="p-2 sm:p-4 whitespace-nowrap">
                                Overview
                              </TableHead>
                              <TableHead className="text-right p-2 sm:p-4 whitespace-nowrap">
                                Daily Avg
                              </TableHead>
                              <TableHead className="text-right p-2 sm:p-4 whitespace-nowrap">
                                Total
                              </TableHead>
                            </TableRow>
                          </TableHeader>
                          <TableBody>
                            <TableRow className="hover:bg-gray-100 transition-colors">
                              <TableCell className="font-medium p-2 sm:p-4 text-sm sm:text-base whitespace-nowrap">
                                Earned
                              </TableCell>
                              <TableCell
                                className={`text-right p-2 sm:p-4 text-sm sm:text-base whitespace-nowrap ${getAmountColor(incomeVsExpenses.income || 0)}`}
                              >
                                {formatCurrency(
                                  (incomeVsExpenses.income || 0) / daysInRange
                                )}
                              </TableCell>
                              <TableCell
                                className={`text-right p-2 sm:p-4 text-sm sm:text-base whitespace-nowrap ${getAmountColor(incomeVsExpenses.income || 0)}`}
                              >
                                {formatCurrency(incomeVsExpenses.income || 0)}
                              </TableCell>
                            </TableRow>
                            <TableRow className="hover:bg-gray-100 transition-colors">
                              <TableCell className="font-medium p-2 sm:p-4 text-sm sm:text-base whitespace-nowrap">
                                Spent
                              </TableCell>
                              <TableCell
                                className={`text-right p-2 sm:p-4 text-sm sm:text-base whitespace-nowrap ${getAmountColor(-incomeVsExpenses.expenses || 0)}`}
                              >
                                {formatCurrency(
                                  (incomeVsExpenses.expenses || 0) / daysInRange
                                )}
                              </TableCell>
                              <TableCell
                                className={`text-right p-2 sm:p-4 text-sm sm:text-base whitespace-nowrap ${getAmountColor(-incomeVsExpenses.expenses || 0)}`}
                              >
                                {formatCurrency(incomeVsExpenses.expenses || 0)}
                              </TableCell>
                            </TableRow>
                            <TableRow className="hover:bg-gray-100 transition-colors">
                              <TableCell className="font-medium p-2 sm:p-4 text-sm sm:text-base whitespace-nowrap">
                                Net
                              </TableCell>
                              <TableCell
                                className={`text-right p-2 sm:p-4 text-sm sm:text-base whitespace-nowrap ${getAmountColor((incomeVsExpenses.income || 0) - (incomeVsExpenses.expenses || 0))}`}
                              >
                                {formatCurrency(
                                  (incomeVsExpenses.income || 0) -
                                    (incomeVsExpenses.expenses || 0)
                                )}
                              </TableCell>
                              <TableCell
                                className={`text-right p-2 sm:p-4 text-sm sm:text-base whitespace-nowrap ${getAmountColor((incomeVsExpenses.income || 0) - (incomeVsExpenses.expenses || 0))}`}
                              >
                                {formatCurrency(
                                  (incomeVsExpenses.income || 0) -
                                    (incomeVsExpenses.expenses || 0)
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </motion.div>
                    </div>
                    <div className="w-full mt-4 md:w-2/3">
                      <IncomeVsExpensesChart
                        data={incomeVsExpenses.dailyBreakdown || []}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-center h-[300px] text-gray-500">
                    <div className="flex flex-col items-center">
                      <svg
                        className="w-16 h-16 mb-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V7a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                        />
                      </svg>
                      <p className="text-lg font-medium">No data available</p>
                      <p className="text-sm">
                        Try selecting a different date range
                      </p>
                    </div>
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
          {accounts && categories && (
            <Card className="mt-8">
              <CardHeader>
                <CardTitle>Recent Transactions</CardTitle>
              </CardHeader>
              <Separator orientation="horizontal" />
              <CardContent className="mt-4 relative">
                {recentTransactionsLoading && (
                  <div className="absolute inset-0 bg-white/50 flex items-center justify-center z-10">
                    <Spinner />
                  </div>
                )}
                <RecentTransactionsTable
                  transactions={recentTransactions}
                  refetchTransactions={handleFetchTransactions}
                  setTransactions={setRecentTransactions}
                  accountOptions={getAccountOptions(accounts)}
                  accounts={accounts}
                  categories={categories}
                  onRowClick={handleRowClick}
                  onEditTransaction={handleEditTransaction}
                  onBulkDelete={handleBulkDelete}
                  onBulkAssign={handleBulkAssign}
                  onBulkUnassign={handleBulkUnassign}
                  onBulkChangeCategory={handleBulkChangeCategory}
                  onBulkChangeDescription={handleBulkChangeDescription}
                  slices={slices}
                  pagination={{
                    currentPage: page,
                    pageSize: perPage,
                    totalPages: totalPages,
                    totalCount: totalCount,
                  }}
                  onPageChange={handlePageChange}
                  onPerPageChange={handlePerPageChange}
                  setTotalPages={setTotalPages}
                  setTotalCount={setTotalCount}
                  isLoading={recentTransactionsLoading}
                  currentFilters={filters}
                  onFilterChange={handleFilterChange}
                />
              </CardContent>
            </Card>
          )}
          {selectedTransaction && (
            <TransactionDetailsView
              transaction={selectedTransaction}
              onClose={() => setSelectedTransaction(null)}
              onEdit={() => setIsEditing(true)}
              isOpen={!!selectedTransaction && !isEditing}
            />
          )}
          {selectedTransaction && (
            <TransactionForm
              transaction={selectedTransaction}
              categories={categories}
              slices={slices}
              onClose={() => {
                setIsEditing(false);
                setSelectedTransaction(null);
                handleFetchTransactions();
              }}
              isOpen={!!selectedTransaction && isEditing}
            />
          )}
        </div>
      </div>
      {isSyncing && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
            <Spinner className="w-8 h-8" />
            <p className="mt-2 text-sm text-gray-500">
              Syncing with your bank...
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

Overview.propTypes = {
  initialDateRange: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
  onDateRangeChange: PropTypes.func,
  plaidStatusData: PropTypes.object,
  refetchPlaidStatus: PropTypes.func,
  accountsData: PropTypes.object,
  refetchAccounts: PropTypes.func,
  categoriesData: PropTypes.object,
  refetchCategories: PropTypes.func,
  slicesData: PropTypes.object,
  refetchSlices: PropTypes.func,
  dateRange: PropTypes.object,
  isSyncing: PropTypes.bool,
  syncJobId: PropTypes.string,
  syncStatusData: PropTypes.object,
  shouldRefereshData: PropTypes.bool,
};

export default Overview;

import { gql } from '@apollo/client';
import { TIME_SEGMENT_FIELDS } from '../fragments/insightFragment';
const GET_SPENDING_BREAKDOWN = gql`
  query GetSpendingBreakdown($startDate: ISO8601Date!, $endDate: ISO8601Date!) {
    spendingBreakdown(startDate: $startDate, endDate: $endDate) {
      categoryBreakdown {
        category {
          id
          name
        }
        amount
      }
      merchantBreakdown {
        merchantName
        amount
      }
    }
  }
`;

const GET_SPENDING_AND_INSIGHTS = gql`
  ${TIME_SEGMENT_FIELDS}

  query GetSpendingAndInsights($startDate: ISO8601Date!, $endDate: ISO8601Date!) {
    spendingBreakdown(startDate: $startDate, endDate: $endDate) {
      categoryBreakdown {
        category {
          id
          name
        }
        amount
      }
      merchantBreakdown {
        merchantName
        amount
        transactionCount
      }
      transactionExtremes {
        id
        name
        merchantName
        amount
        date
      }
    }
    financialInsights(limit: 1) {
      id
      generatedAt
      confidenceScore
      sevenDays: timeSegment(days: 7) {
        ...TimeSegmentFields
      }
      fourteenDays: timeSegment(days: 14) {
        ...TimeSegmentFields
      }
      thirtyDays: timeSegment(days: 30) {
        ...TimeSegmentFields
      }
      sixtyDays: timeSegment(days: 60) {
        ...TimeSegmentFields
      }
      ninetyDays: timeSegment(days: 90) {
        ...TimeSegmentFields
      }
      merchantPatterns {
        id
        merchantName
        frequency
        averageAmount
        confidence
      }
      recurringPatterns {
        weekly {
          merchant
          categoryId
          dayOfWeek
          averageAmount
          confidence
        }
        monthly {
          merchant
          categoryId
          dayOfMonth
          averageAmount
          confidence
        }
        confidenceScores {
          weeklyPatterns {
            merchantConfidenceMap
          }
          monthlyPatterns {
            merchantConfidenceMap
          }
          overallConfidence
        }
      }
    }
  }
`;

const GET_INCOME_VS_EXPENSES = gql`
  query GetIncomeVsExpenses($startDate: ISO8601Date!, $endDate: ISO8601Date!) {
    incomeVsExpenses(startDate: $startDate, endDate: $endDate) {
      income
      expenses
      dailyBreakdown {
        date
        income
        expenses
      }
    }
  }
`;

export { GET_SPENDING_BREAKDOWN, GET_INCOME_VS_EXPENSES, GET_SPENDING_AND_INSIGHTS };

import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";
import { Wallet } from "lucide-react";
import { Badge } from "./ui/Badge";
import { formatInsightAmount } from "../lib/utils";

const getStatusColor = (status: string) => {
  switch (status.toLowerCase()) {
    case "over":
      return "bg-red-100 text-red-800 hover:bg-red-200";
    case "under":
      return "bg-yellow-100 text-yellow-800 hover:bg-yellow-200";
    case "optimal":
      return "bg-green-100 text-green-800 hover:bg-green-200";
    default:
      return "bg-gray-100 text-gray-800 hover:bg-gray-200";
  }
};

export const BudgetInsightsPanel = ({ insights }) => {
  if (!insights?.length) return null;

  return (
    <Card className="overflow-hidden">
      <CardHeader className="border-b border-gray-100 bg-gray-50/50">
        <CardTitle className="flex items-center gap-3">
          <Wallet className="h-6 w-6 text-purple-500" />
          <div>
            <h3 className="text-xl font-semibold">Budget Performance</h3>
            <p className="text-base text-gray-500 font-normal">
              Monitor your slices and optimize allocations
            </p>
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent className="p-6">
        <div className="space-y-6">
          {insights.map((insight, index) => (
            <div
              key={index}
              className="border-b border-gray-100 pb-6 last:border-0 last:pb-0"
            >
              <div className="flex justify-between items-start mb-3">
                <h4 className="text-base font-medium text-gray-900">
                  {insight.sliceName}
                </h4>
                <Badge className={getStatusColor(insight.status)}>
                  {insight.status.charAt(0).toUpperCase() +
                    insight.status.slice(1)}
                </Badge>
              </div>
              <div className="bg-gray-50 rounded-lg p-4 mb-4">
                <div className="flex justify-between items-center">
                  <div>
                    <div className="text-sm text-gray-500 mb-1">Trend</div>
                    <div className="text-base font-medium text-gray-900">
                      {insight.trend.charAt(0).toUpperCase() +
                        insight.trend.slice(1)}
                    </div>
                  </div>
                  <div className="text-right">
                    <div className="text-sm text-gray-500 mb-1">Difference</div>
                    <div
                      className={`text-base font-medium ${insight.amountDiff > 0 ? "text-red-600" : "text-green-600"}`}
                    >
                      {formatInsightAmount(Math.abs(insight.amountDiff))}
                    </div>
                  </div>
                </div>
              </div>
              {insight.specificRecommendations?.length > 0 && (
                <div className="bg-gray-50 rounded-lg p-4">
                  <h5 className="text-base font-semibold text-gray-900 mb-3">
                    Recommended Actions
                  </h5>
                  <div className="space-y-4">
                    {insight.specificRecommendations.map((rec, idx) => (
                      <div key={idx} className="text-base">
                        <p className="text-gray-800 font-medium mb-2">
                          {rec.action}
                        </p>
                        <div className="flex justify-between text-sm">
                          <span className="text-purple-600 font-medium">
                            Impact: {formatInsightAmount(rec.impact)}
                          </span>
                        </div>
                        {rec.implementationSteps?.length > 0 && (
                          <ul className="mt-2 space-y-1">
                            {rec.implementationSteps.map((step, stepIdx) => (
                              <li
                                key={stepIdx}
                                className="text-sm text-gray-600 flex items-start gap-2"
                              >
                                <span className="text-blue-500">•</span>
                                {step}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { format } from "date-fns";
import { GET_FINANCIAL_INSIGHTS } from "../queries/financialInsightsQueries";
import { RiskAlertsPanel } from "./RiskAlertPanel";
import { SavingsInsightsPanel } from "./SavingInsightsPanel";
import { OptimizationSuggestionsPanel } from "./OptimizationSuggestionsPanel";
import { SpendingInsightsPanel } from "./SpendingInsightsPanel";
import { Spinner } from "./ui/Spinner";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";
import { BrainCircuit, Sparkles } from "lucide-react";
import { BudgetInsightsPanel } from "./BudgetInsightsPanel";
import { TimeSegmentSelector } from "./TimeSegmentSelector";

const getTimeSegmentKey = (segment: string): string => {
  const segmentMap = {
    "7": "sevenDays",
    "14": "fourteenDays",
    "30": "thirtyDays",
    "60": "sixtyDays",
    "90": "ninetyDays",
  };

  return segmentMap[segment] || "thirtyDays";
};

export default function InsightsDisplay() {
  const [selectedSegment, setSelectedSegment] = useState("14");
  const { data, loading, error } = useQuery(GET_FINANCIAL_INSIGHTS, {
    variables: { limit: 1 },
  });

  const handleSegmentChange = (segment: string) => {
    setSelectedSegment(segment);
  };

  const getSegmentedInsights = () => {
    if (parseInt(selectedSegment) > 90) {
      return {
        ...data?.financialInsights[0]?.ninetyDays,
        limitedInsight: true,
      };
    }

    const segmentKey = getTimeSegmentKey(selectedSegment);
    const insight = data?.financialInsights[0];

    if (!insight) return null;

    return {
      spendingInsights: insight[segmentKey]?.spendingInsights || [],
      budgetInsights: insight[segmentKey]?.budgetInsights || [],
      savingsInsights: insight[segmentKey]?.savingsInsights || [],
      riskAlerts: insight[segmentKey]?.riskAlerts || [],
      optimizationSuggestions:
        insight[segmentKey]?.optimizationSuggestions || [],
      generatedAt: insight.generatedAt,
      confidenceScore: insight.confidenceScore,
    };
  };

  if (loading) return <Spinner />;
  if (error) return <div>Error loading insights</div>;

  if (!data?.financialInsights?.length) {
    return (
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <BrainCircuit className="h-6 w-6 text-blue-500" />
            <span className="text-xl">AI Financial Insights</span>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="text-center py-12">
            <Sparkles className="h-12 w-12 text-blue-400 mx-auto mb-4" />
            <p className="text-lg text-gray-600 mb-2">
              Analyzing your financial data...
            </p>
            <p className="text-sm text-gray-500">
              Check back soon for personalized insights and recommendations.
            </p>
          </div>
        </CardContent>
      </Card>
    );
  }

  const insight = getSegmentedInsights();

  return (
    <div className="w-full space-y-8">
      <div className="flex justify-between items-center mb-4">
        <TimeSegmentSelector
          selectedSegment={selectedSegment}
          onSegmentChange={handleSegmentChange}
        />
        {parseInt(selectedSegment) > 90 && (
          <div className="text-sm text-yellow-600">
            Insights limited to 90 days of data
          </div>
        )}
      </div>
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center border-b border-gray-200 pb-4 gap-4">
        <div>
          <h2 className="text-2xl font-semibold tracking-tight mb-2 flex items-center gap-2">
            <BrainCircuit className="h-6 w-6 text-blue-500" />
            AI Insights
          </h2>
          <p className="text-sm text-gray-500 leading-relaxed">
            {`Smart recommendations based on your last ${selectedSegment} days of spending`}
          </p>
        </div>
        <div className="text-left sm:text-right space-y-1">
          <div className="text-sm font-medium text-gray-900">
            Last Updated: {format(new Date(insight.generatedAt), "PPP")}
          </div>
          <div className="text-sm text-gray-500">
            AI Confidence:{" "}
            <span className="font-medium text-blue-600">
              {(insight.confidenceScore * 100).toFixed(1)}%
            </span>
          </div>
        </div>
      </div>

      <div className="flex flex-col space-y-6">
        <SpendingInsightsPanel insights={insight.spendingInsights} />
        <BudgetInsightsPanel insights={insight.budgetInsights} />
        <RiskAlertsPanel alerts={insight.riskAlerts} />
        <SavingsInsightsPanel insights={insight.savingsInsights} />
        <OptimizationSuggestionsPanel
          suggestions={insight.optimizationSuggestions}
        />
      </div>
    </div>
  );
}

import { gql } from '@apollo/client';
import { TIME_SEGMENT_FIELDS } from '../fragments/insightFragment';

export const GET_FINANCIAL_INSIGHTS = gql`

  ${TIME_SEGMENT_FIELDS}

  query GetFinancialInsights($limit: Int, $startDate: ISO8601DateTime, $endDate: ISO8601DateTime) {
    financialInsights(limit: $limit, startDate: $startDate, endDate: $endDate) {
      id
      generatedAt
      confidenceScore
      sevenDays: timeSegment(days: 7) {
        ...TimeSegmentFields
      }
      fourteenDays: timeSegment(days: 14) {
        ...TimeSegmentFields
      }
      thirtyDays: timeSegment(days: 30) {
        ...TimeSegmentFields
      }
      sixtyDays: timeSegment(days: 60) {
        ...TimeSegmentFields
      }
      ninetyDays: timeSegment(days: 90) {
        ...TimeSegmentFields
      }
    }
  }
`;

import React, { useMemo } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";
import SpendingBreakdownChart from "./ui/SpendingBreakdownChart";
import { COLORS, formatCurrency, getAmountColor } from "../lib/utils";

export default function SpendingOverview({ data }) {
  const totalSpending = useMemo(() => {
    if (!data?.categoryBreakdown) return 0;
    return data.categoryBreakdown.reduce(
      (sum, item) => sum + Math.abs(item.amount),
      0
    );
  }, [data]);

  const spendingInsights = useMemo(() => {
    if (!data?.merchantBreakdown?.length) return null;

    const merchants = [...data.merchantBreakdown];
    const sortedByAmount = [...merchants].sort(
      (a, b) => Math.abs(b.amount) - Math.abs(a.amount)
    );

    const totalTransactionCount = merchants.reduce(
      (sum, m) => sum + m.transactionCount,
      0
    );

    const extremes = data.transactionExtremes || [];
    const largest = extremes.find(
      (t) =>
        Math.abs(t.amount) ===
        Math.max(...extremes.map((e) => Math.abs(e.amount)))
    );
    const smallest = extremes.find(
      (t) =>
        Math.abs(t.amount) ===
        Math.min(...extremes.map((e) => Math.abs(e.amount)))
    );

    return {
      largestMerchant: sortedByAmount[0] || null,
      smallestMerchant: sortedByAmount[sortedByAmount.length - 1] || null,
      largestTransaction: largest || null,
      smallestTransaction: smallest || null,
      averageTransaction:
        totalTransactionCount > 0
          ? merchants.reduce((sum, m) => sum + Math.abs(m.amount), 0) /
            totalTransactionCount
          : 0,
      totalTransactions: totalTransactionCount,
    };
  }, [data]);

  const sortedCategories = useMemo(() => {
    if (!data?.categoryBreakdown) return [];
    return [...data.categoryBreakdown].sort(
      (a, b) => Math.abs(b.amount) - Math.abs(a.amount)
    );
  }, [data]);

  const sortedMerchants = useMemo(() => {
    if (!data?.merchantBreakdown) return [];
    return [...data.merchantBreakdown]
      .sort((a, b) => Math.abs(b.amount) - Math.abs(a.amount))
      .slice(0, 5);
  }, [data]);

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card>
          <CardHeader>
            <CardTitle>Spending Summary</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {spendingInsights ? (
                <>
                  <div>
                    <h3 className="text-base font-medium text-gray-500">
                      Largest Single Transaction
                    </h3>
                    <p className="mt-1 text-2xl font-semibold">
                      {formatCurrency(
                        Math.abs(
                          spendingInsights.largestTransaction?.amount || 0
                        )
                      )}
                    </p>
                    <p className="text-base text-gray-500">
                      {spendingInsights.largestTransaction?.merchantName ||
                        "No merchant"}
                    </p>
                    <p className="text-xs text-gray-400">
                      {new Date(
                        spendingInsights.largestTransaction?.date
                      ).toLocaleDateString()}
                    </p>
                  </div>
                  <div>
                    <h3 className="text-base font-medium text-gray-500">
                      Smallest Single Transaction
                    </h3>
                    <p className="mt-1 text-2xl font-semibold">
                      {formatCurrency(
                        Math.abs(
                          spendingInsights.smallestTransaction?.amount || 0
                        )
                      )}
                    </p>
                    <p className="text-base text-gray-500">
                      {spendingInsights.smallestTransaction?.merchantName ||
                        "No merchant"}
                    </p>
                    <p className="text-xs text-gray-400">
                      {new Date(
                        spendingInsights.smallestTransaction?.date
                      ).toLocaleDateString()}
                    </p>
                  </div>
                  <div>
                    <h3 className="text-base font-medium text-gray-500">
                      Average Transaction
                    </h3>
                    <p className="mt-1 text-2xl font-semibold">
                      {formatCurrency(spendingInsights.averageTransaction)}
                    </p>
                    <p className="text-base text-gray-500">
                      across {spendingInsights.totalTransactions} transactions
                    </p>
                  </div>
                  <div>
                    <h3 className="text-base font-medium text-gray-500">
                      Total Spending
                    </h3>
                    <p className="mt-1 text-2xl font-semibold">
                      {formatCurrency(totalSpending)}
                    </p>
                    <p className="text-base text-gray-500">
                      Daily Avg: {formatCurrency(totalSpending / 30)}
                    </p>
                  </div>
                </>
              ) : (
                <div className="text-center text-gray-500">
                  No data available
                </div>
              )}
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Top places you spend your money</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4 sm:space-y-6">
              {sortedMerchants?.length > 0 ? (
                sortedMerchants.map((merchant, index) => {
                  const percentage =
                    merchant?.amount && totalSpending
                      ? (
                          (Math.abs(merchant.amount) / totalSpending) *
                          100
                        ).toFixed(1)
                      : "0.0";
                  return (
                    <div
                      key={merchant?.id || index}
                      className="flex items-center justify-between hover:bg-gray-50 p-2 rounded-lg transition-colors"
                    >
                      <div className="flex items-center gap-3">
                        <div
                          className="w-4 h-4 rounded-full flex-shrink-0"
                          style={{
                            backgroundColor: COLORS[index % COLORS.length],
                          }}
                        />
                        <div>
                          <span className="font-medium leading-none">
                            {merchant?.merchantName || "Unknown Merchant"}
                          </span>
                          <div className="text-sm text-gray-500 mt-0.5">
                            {merchant?.transactionCount || 0} transactions
                          </div>
                        </div>
                      </div>
                      <div className="text-right">
                        <div
                          className={`${getAmountColor(merchant?.amount)} font-semibold`}
                        >
                          {formatCurrency(merchant?.amount || 0)}
                        </div>
                        <div className="text-sm text-gray-500 mt-0.5">
                          {percentage}%
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="text-center text-gray-500">
                  No merchant data available
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      </div>
      <Card>
        <CardHeader>
          <CardTitle>Category Breakdown</CardTitle>
        </CardHeader>
        <CardContent className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex items-center justify-center h-full w-full order-1 md:order-1">
            <div className="w-full max-w-[500px]">
              <SpendingBreakdownChart
                data={data?.categoryBreakdown || []}
                colors={COLORS}
              />
            </div>
          </div>
          <div className="border-none md:border-l pl-0 md:pl-6 relative z-10 order-2 md:order-2">
            <div className="space-y-4 sm:space-y-2">
              {sortedCategories.map((category, index) => {
                const percentage =
                  category?.amount && totalSpending
                    ? (
                        (Math.abs(category.amount) / totalSpending) *
                        100
                      ).toFixed(1)
                    : "0.0";
                return (
                  <div
                    key={category.category.id}
                    className="flex items-center justify-between hover:bg-gray-50 p-2 rounded-lg transition-colors"
                  >
                    <div className="flex items-center gap-3">
                      <div
                        className="w-4 h-4 rounded-full flex-shrink-0"
                        style={{
                          backgroundColor: COLORS[index % COLORS.length],
                        }}
                      />
                      <span className="font-medium leading-none">
                        {category.category.name}
                      </span>
                    </div>
                    <div className="text-right">
                      <div
                        className={`${getAmountColor(category.amount, "text")} font-semibold`}
                      >
                        {formatCurrency(category.amount)}
                      </div>
                      <div className="text-sm text-gray-500 mt-0.5">
                        {percentage}%
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
